<template>
    <div class="p-card p-grid p-shadow-3">
        <Toast />
        <h6 class="p-col-12">{{ $t('menu.shops') }}</h6>
        <Fieldset :legend="$t('authorization.addShopToGroup')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
            <div class="p-grid">
                <div class="p-col-12 p-md-2">
                    <span class="p-float-label">
                        <Dropdown id="dropdown" style="width: 100%" v-model="shopModel" :options="shopsNotInGroup" optionLabel="name" :filter="!isMobile()" />
                        <label for="dropdown">{{ $t('dropdown.placeholder.shop') }}</label>
                    </span>
                </div>
                <div class="p-col-12 p-md-2 p-text-right">
                    <Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="addShop()" />
                </div>
            </div>
        </Fieldset>
        <div class="p-col-12 p-grid" v-for="(shop, index) in groupShops" :key="'shop' + index">
            <div class="p-col-1">{{ index + 1 }}</div>
            <div class="p-col-6">{{ shop.name }}</div>
            <div class="p-col-5 p-text-left">
                <Button :label="$t('buttons.remove')" @click="removeShop(shop._id)" class="p-button-danger p-button-text p-pt-0" />
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../../service/BoApi';
import { BoApiAuthorization } from '../../../service/BoApiAuthorization';

export default {
    name: 'shopsList',
    data() {
        return {
            groupShops: [],
            shops: [],
            BoApi: new BoApi(),
            shopModel: {},
            BoApiAuthorization: new BoApiAuthorization(),
        };
    },

    props: {
        shopsIds: {
            type: Array,
            required: true,
        },
        groupId: {
            type: String,
            required: true,
        },
    },
    computed: {
        shopsNotInGroup() {
            return this.shops.filter((shop) => {
                return !this.groupShops.find((groupShop) => {
                    return groupShop._id === shop._id;
                });
            });
        },
    },
    mounted() {
        this.getGroupShops();
        this.BoApi.getShops('')
            .then((responseShop) => {
                this.shops = responseShop.data;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
    methods: {
        getGroupShops() {
            this.BoApi.getShopsList(this.shopsIds)
                .then((response) => {
                    this.groupShops = response.data;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        addShop() {
            this.BoApiAuthorization.addShopToGroup(this.shopModel._id, this.groupId)
                .then(() => {
                    this.groupShops.push(this.shopModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', error.Message);
                });
        },
        removeShop(shopId) {
            this.BoApiAuthorization.removeShopFromGroup(shopId, this.groupId)
                .then(() => {
                    this.groupShops = this.groupShops.filter((shop) => shop._id !== shopId);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', error.Message);
                });
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>

<style lang="scss" scoped>
.circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    display: inline-block;
}
</style>
