<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('authorization.authorizationShopsGroups') }}</h5>
                <DataTable :value="groups" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRows" stateStorage="session" stateKey="dt-state-markettemplates">
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="type" :header="$t('general.type')" :sortable="true"></Column>
                    <Column field="description" :header="$t('general.description')"></Column>
                    <template #expansion="slotProps" class="p-text-right">
                        <ShopsList :shopsIds="slotProps.data.shops" :groupId="slotProps.data._id"></ShopsList>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApiAuthorization } from '../../service/BoApiAuthorization';
import { uiSettings } from '../../settings/uiSettings';
import ShopsList from './Components/ShopsList';
export default {
    components: {
        ShopsList,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            groups: [],
            BoApiAuthorization: new BoApiAuthorization(),
            loading: true,
            totalPages: 0,
            crtRoute: 'authorization',
            expandedRows: [],
        };
    },
    mounted() {
        this.BoApiAuthorization.getShopsGroups()
            .then((response) => {
                this.groups = response.data;
                this.loading = false;
            })
            .catch((error) => {
                this.loading = false;
                this.checkForUnauthorizedError(error);
            });
    },
};
</script>

<style scoped lang="scss">
</style>