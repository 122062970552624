import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoApiAuthorization {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl+'/authorization/',
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    getShopsGroups() {
        return this.Api.get('getShopsGroups')
    }
    addShopToGroup(shopId, groupId) {
        var requestDetails = {
            ShopID :shopId,
            GroupId: groupId
        };
        return this.Api.post('addShopToGroup',requestDetails)
    }
    removeShopFromGroup(shopId, groupId) {
        var requestDetails = {
            ShopID :shopId,
            GroupId: groupId
        };
        return this.Api.post('removeShopFromGroup',requestDetails)
    }
      
}

export {
    BoApiAuthorization
}
